












































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { ElUploadInternalFileDetail } from 'element-ui/types/upload'

@Component
export default class ImportData extends Vue {
  @Prop({ default: {} }) private uploadInfo!: { importUrl: string; templateUrl: string; templateName: string }
  @Prop({ default: false }) private isNeedProject!: boolean
  @Prop({ default: false }) private isShowImport!: boolean

  private fullscreenLoading = false
  private title = '导入'
  private isShowForm = true
  private ruleForm: { projectId: string | null; fileName: string | null} = {
    projectId: '',
    fileName: ''
  }

  private rules = {
    projectId: [{ required: true, message: '请选择项目', trigger: 'change' }],
    fileName: [{ required: true, message: '请上传数据' }]
  }

  private fileList: ElUploadInternalFileDetail[] = []
  private headInfo = {
    token: window.localStorage.getItem('token') || '',
    customer: window.localStorage.getItem('customerCode') || ''
  }

  private resData = {
    success: 0,
    error: 0
  }

  private resultList = []

  get projectList () {
    return this.$store.state.projectList
  }

  $refs!: {
    ruleForm: ElForm;
    upload: any;
    resultTable: any;
  }

  // 文件改变
  uploadChange (file: ElUploadInternalFileDetail) {
    if (this.isNeedProject) {
      this.fileList = []
      this.ruleForm.fileName = ''
      const beforeUpload = this.beforeUpload(file)
      if (beforeUpload) {
        this.fileList.push(file)
        this.ruleForm.fileName = file.name
        if (this.ruleForm.fileName) {
          this.$refs.ruleForm.clearValidate('fileName')
        }
      }
    }
  }

  // 文件上传之前
  beforeUpload (file: ElUploadInternalFileDetail) {
    const size = file.size / 1024 / 1024 < 10
    const type = 'xls,xlsx'
    const name = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase()
    if (type.indexOf(name) === -1) {
      this.$message({
        message: '上传文件只能是 .xls,.xlsx 格式!',
        type: 'warning'
      })
      return false
    } else if (!size) {
      this.$message({
        message: '上传文件大小不能超过 10MB!',
        type: 'warning'
      })
      return false
    } else {
      if (!this.isNeedProject) {
        this.fullscreenLoading = true
      }
      return true
    }
  }

  // 文件上传失败
  fileError (error: ErrorEvent) {
    this.$nextTick(() => {
      this.fileList = []
      this.ruleForm.fileName = ''
    })
    this.fullscreenLoading = false
    this.$message.error(JSON.parse(error.message).message)
  }

  // 文件上传成功
  successFile (res: any) {
    this.fullscreenLoading = false
    this.isShowForm = false
    this.title = '导入结果'
    if (res.data.error === 0) {
      this.$emit('update:isShowImport', false)
      this.$emit('onSuccess')
      this.$message.success('已成功导入' + res.data.success + '条，失败0条')
    } else {
      this.resData = {
        error: res.data.error || 0,
        success: res.data.success || 0
      }
      this.$emit('onSuccess')
      this.resultList = res.data.list || []
    }
  }

  // 取消
  closeDialog () {
    this.title = '导入'
    this.$emit('update:isShowImport', false)
    this.isShowForm = true
    this.ruleForm = {
      projectId: null,
      fileName: null
    }
    this.$nextTick(() => {
      this.resultList = []
      this.resData = {
        success: 0,
        error: 0
      }
      this.$refs.ruleForm.resetFields()
    })
  }

  // 保存
  submit () {
    this.$refs.ruleForm.validate((valid) => {
      if (valid) {
        this.fullscreenLoading = true
        this.$refs.upload.submit()
      }
    })
  }
}
