














































































import { Component, Vue } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import ImportData from '@/components/importTemplateData/Index.vue'
import { DeviceInfo, AllocateInfo } from '@/types/device'

@Component({
  name: 'List',
  components: { ImportData }
})
export default class List extends Vue {
  private showAllocateDialog = false
  private searchInfo = {
    deviceNumber: '',
    allotStatus: '',
    allotUserName: ''
  }

  private allocateInfo: AllocateInfo = {
    deviceIdList: [],
    allotUserId: ''
  }

  private allocateRules = {
    allotUserId: [
      { required: true, message: '请选择账户', trigger: 'change' }
    ]
  }

  private selectInfo: DeviceInfo[] = []
  private uploadInfo = {}
  private isShowImport = false
  private loading = false
  private tableData: DeviceInfo[] = []
  private page = 1
  private size = 10
  private total = 0
  private userList = []

  created () {
    this.getData()
    this.getUserList()
  }

  getData () {
    this.loading = true
    this.$axios.get<{ total: number; list: Array<DeviceInfo> }>(this.$apis.device.getDeviceByPage, {
      ...this.searchInfo,
      page: this.page,
      size: this.size
    })
      .then((res) => {
        this.total = res.total || 0
        this.tableData = res.list || []
      })
      .finally(() => {
        this.loading = false
      })
  }

  getUserList () {
    this.$axios.get(this.$apis.developer.selectApiUserList)
      .then((res) => {
        this.userList = res.list || []
      })
  }

  onSearch () {
    this.page = 1
    this.getData()
  }

  onHistory (row: DeviceInfo) {
    this.$router.push({
      name: 'historyData',
      params: { deviceId: row.deviceId },
      query: { typeId: row.deviceTypeId }
    })
  }

  // 新增设备
  addDevice () {
    this.$router.push({ name: 'addDevice' })
  }

  // 导入工人
  importDevice (): void {
    this.getDicList()
  }

  getDicList () {
    this.$axios.get(this.$apis.common.selectDicByList, { dicType: 'deviceImportTemplate' }).then(res => {
      if (!res.deviceImportTemplate || res.deviceImportTemplate.length === 0) {
        this.$message.error('请先配置导入模板')
      } else {
        this.uploadInfo = {
          templateUrl: res.deviceImportTemplate[0].dicValue,
          templateName: res.deviceImportTemplate[0].dicTypeDesc,
          importUrl: this.$apis.device.importDevice
        }
        this.isShowImport = true
      }
    })
  }

  // 选择行
  handleSelectionChange (val: DeviceInfo[]): void {
    this.selectInfo = val
  }

  // 批量分配
  batchAllocate (): void {
    if (this.selectInfo.length > 0) {
      this.showAllocateDialog = true
      this.allocateInfo.deviceIdList = []
      this.selectInfo.forEach((item: DeviceInfo) => {
        this.allocateInfo.deviceIdList.push(item.deviceId)
      })
    } else {
      this.$message.error('请选择要分配的设备')
    }
  }

  // 分配
  allocateCustomer (row: DeviceInfo): void {
    this.showAllocateDialog = true
    this.allocateInfo.deviceIdList = [row.deviceId]
  }

  // 分配数据校验
  allocateDevice (): void {
    (this.$refs.allocateInfo as ElForm).validate(valid => {
      if (valid) {
        this.doAllocateDevice()
      }
    })
  }

  // 分配执行
  doAllocateDevice () {
    this.$axios.post(this.$apis.device.batchAllocateDevice, this.allocateInfo).then(() => {
      this.$message.success('分配成功')
      this.getData()
    }).finally(() => {
      (this.$refs.allocateInfo as ElForm).resetFields()
      this.showAllocateDialog = false
    })
  }

  // 回收
  recoverDevice (id: string): void {
    this.$confirm('是否回收此设备?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.doRecoverDevice(id)
    })
  }

  // 执行回收
  doRecoverDevice (id: string): void {
    this.$axios.post(this.$apis.device.batchRecycle, { deviceIdList: [id] }).then(() => {
      this.$message.success('操作成功')
      this.getData()
    })
  }

  // 判断设备是否能分配
  selectable (row: {allotStatus: string}) {
    if (row.allotStatus === '0') {
      return true
    } else {
      return false
    }
  }
}
